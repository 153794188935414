import React from 'react';

import Layout from "../../components/layout";
import { Link } from 'gatsby';
import { Title,Testonimal } from "../../components/Globals";
import {Helmet} from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import  "./awesome-slider-override.css";
// import 'react-awesome-slider/src/styles.js';

const SalesforceConsultingPage = () => {

  const [services] = React.useState([
    {
      id: 1,
      title: 'Salesforce Consulting',
      desc: 'This consulting involves the implementation of Salesforce, Force.com application development, its maintenance and support, all the automation involved, be it marketing automation or workflow automation. ',
      image: 'salesforce-partners-mumbai-newyork.png',
      alt:'Salesforce partners & consulting company',
      imagetitle:'Salesforce Consulting Services ',
      url: {
        title: 'Salesforce Development Services',
        url: '/services/salesforce-development-services'
      }
    },
    {
      id: 2,
      title: 'Salesforce Integration Services',
      desc: 'reate an integrated software environment with one fo the top Salesforce Integrators- Cymetrix. Our Salesforce integration services help businesses to achieve an uninterrupted flow of information. ',
      image: 'salesforce-partners-mumbai-newyork.png',
      alt:'Salesforce Integrators',
      imagetitle:'Salesforce Integration Services',
      url: {
        title: 'Salesforce Integration Services',
        url: '/services/sdfc-integration-services/'
      }
    },
    {
      id: 3,
      title: 'Salesforce Maintenance & Support Services',
      desc: 'Your Salesforce journey thrives with continuous support. Our experts are available 24/7, ensuring peak performance, governance, critical issue resolution - our flexible models keep your Salesforce ecosystem smooth. ',
      image: 'salesforce-partners-mumbai-newyork.png',
      alt:'Salesforce Partners In India, USa, UK, Japan for maintenance support',
      imagetitle:'Salesforce Support',
      url: {
        title: 'Salesforce Maintenance & Support Services',
        url: '/services/salesforce-maintenance-support-services/'
      }
    },
    {
      id: 4,
      title: 'Mulesoft Consulting',
      desc: 'We also provide Mulesoft integration services where we help you to eliminate point-to-point integration and provide the integration platform to connect applications, data sources, and APIs.',
      image: 'Mulesoft.png',
      alt:'Mulesoft Consulting by Salesforce implementation partner in Mumbai,San Francisco, NY Cymetrix',
      imagetitle:'Cymetrix Software Mulesoft Consulting Services',
      url: {
        title: 'Mulesoft Consulting Services',
        url: '/services/mulesoft-consulting/'
      }
    },

    {
      id: 5,
      title: 'Marketing Automation',
      desc: 'We distinguishe ourselves through our end-to-end marketing automation services. It includes Salesforce Marketing Cloud implementation, creation and management of marketing campaigns & content development, along with an integrated strategy which supports a unified CX.',
      image: 'salesforce-partners-mumbai-newyork.png',
      alt:'Salesforce consulting companies india usa',
      imagetitle:'Salesforce Marketing Automation Services',
      url: {
        title: 'Marketing Automation Services',
        url: '/services/marketing-automation-services/'
      }
    },
 
 
  ]);

  const testimonials = [
    {
      customerName: "David Hanson",
      customerProfile: "CEO, Koalify",
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
      quote:
        "We have been using servana for about 2 years. And in that time we have had no problem at all. The user interface is really simple to use. Our services scale automatically and we never have to worry about downtimes. is as described."
    },
    {
      customerName: "Serena Davis",
      customerProfile: "Founder, Travana",
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=256&h=256&q=80",
      quote:
        "We are delighted with the quality and performance of the servers that servana provides. The uptime is amazing and the internet connection is great for the price we are paying."
    },
    {
      customerName: "Timothy Burr",
      customerProfile: "CTO, Coronax",
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=256&h=256&q=80",
      quote:
        "It has been 8 months since we have switched to servana and it has nothing but an amazing experience. The cost is affordable, support is great, uptime is as described."
    }
  ];

  return (
    <Layout>
       
        <div className="salesforce-consulting h-screen">
        <Helmet>
        <title>Top Salesforce Partner & Consulting Company | USA, India, UK</title>
        <meta name="description" content="Cymetrix is one of the top Salesforce partners & CRM consulting companies in USA (NY,San Fran), India(Mumbai) & UK- your Salesforce implementation partner for digital transformation & unified CX."/>
        <meta name="keywords" content="cymetrix,salesforce partners, salesforce implementation partners, salesforce sonsulting services, salesforce cosnulting companies, salesforce consulting partners, sfdc consultants, salesforce consulting, salesforce crm software ,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce usa, salesforce mumbai,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/services/salesforce-consulting/" />
      </Helmet>
            <h1 className="text-base xl:text-4xl xl:m-64 md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
            text-white text-uppercase text-center">
             TOP SALEFORCE CONSULTING COMPANY TO CREATE CUSTOMER MAGIC THROUGH AI+DATA+CRM				
            </h1>
        </div>
        
        <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              ONE OF THE INNOVATIVE AND TOP SALESFORCE CONSULTING COMPANIES IN USA 
 														
              </h1><br />
              <p className="font-hairline text-justify">Cymetrix Software is empowering businesses globally with offices in San Francisco & New York (USA), Mumbai (India), and Reading(UK). We aim at 360° Salesforce offshore support in context to Salesforce consultation. We are armed with all the means and ways to make you achieve your business goal with ease. We provide FULL-CYCLE SALESFORCE CONSULTING SERVICES at every step of the digital transformation. Our expertise lies in areas like the Salesforce Sales Cloud, Salesforce Marketing Cloud, Salesforce Service Cloud, distributed marketing, Salesforce Community Cloud as well as Salesforce Commerce Cloud. We have performed various integrations of Salesforce with Heroku, Shield, IoT cloud as well as Einstein Analytics.				
              </p><br/>
              <p className="font-hairline text-justify">As a Salesforce Implementation Partner, we follow the best practices and
cover all the parts of Salesforce implementation services right from the integration of Salesforce to your system platform. We are experts at working in Salesforce Lightning as well as classic. Over the years we have gained experience in financial services cloud, higher education cloud, health cloud, government cloud as well as NPSP cloud. We have worked on salesforce.org where we have provided solutions for nonprofits, for educational purposes as well as for philanthropic organizations. We believe in sharing and growing together. Hence, we are involved in Salesforce training services as well where we train beginners to the expert level in all the domains of Salesforce. Along with this, all our projects and clients have enabled us to gain more experience in all the above-mentioned fields and platforms of the salesforce.With every new project, we evolve as an innovative IT solution and top companies implementing salesforce CRM in India
and USA						
              </p>
              <div className="flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8">
                  <Link to="/about/company" className="uppercase font-bold px-8 lg:px-10 py-3 rounded bg-gray-500 text-gray-100 hover:bg-hover focus:shadow-outline focus:outline-none transition duration-300">
                  About Cymetrix
                  </Link>
              </div>
              </div>
              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-consulting-company-customer-360.png')}
                      alt="Cymterix  Software's Salesforce Consulting Services"
                      title="Cymetrix solutions powered by Salesforce cusustomer 360 "
                      />
                </div>
              </div>
          </div>
        </div>

        <div className="product-background w-full flex flex-col items-center" title="">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Cymetrix Industry Solutions Powered By Salesforce</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                    <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">HEALTHCARE & PHARMA</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Creating transformative solutions to assist healthcare service providers in managing patient data securely and integrating various procedures to increase productivity and drive higher value.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">BFSI</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Innovative solutions for BFSI to make the best use of data, enhance operational efficiency and reduce costs, comply with regulatory norms and fulfill social responsibility and improve employee-customer collaboration.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">MANUFACTURING</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Solving manufacturers’ greatest challenges related to ensuring a swift end-to-end process through efficient sales and customer management, inventory and supply chain management, collaboration, and communication.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">FINTECH</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Enabling fintech firms to elevate customer experiences, personalize customer experience, improve partner collaboration, automate and streamline processes and scale cost-effectively.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">E-COMMERCE</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Delivering seamless, personalised B2C & B2B experiences across digital, social, mobile, and store.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">HIGH TECH</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Creating solutions which help technology companies improve XaaS business models, customer service, data analytics, and more.</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">NONPROFIT</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Creating solutions for nonprofits, educational purposes as well as for philanthropic organizations.</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>

          <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              CYMETRIX SALESFORCE EXPERTISE 
 														
              </h1><br />
              <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/top-salesforce-implementation-partners-usa-india-uk.png')}
                      alt="Salesforce Partners"
                      title="Cymterix Salesforce Expertise "
                      />
              </div>
              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">               
             
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
             SALESFORCE CERTIFIED TEAM 
 														
              </h1><br /> 
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-certified-consultants-usa-india.png')}
                      alt="salesforce consulting companies"
                      title="Skilled team of Salesforce certified consultants"
                      />
                
              </div>
          </div>
        </div>

        <div className="service-background w-full flex flex-col items-center ">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="OUR SALESFORCE CONSULTING SERVICES" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                {
                  services.map(service => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/4" key={service.id}>
                          <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">
                              <img src={require(`../../images/services/${service.image}`)} title={service.imagetitle} alt={service.alt} className="w-24 h-24" />
                              </div>
                              <h1 className="mt-5 text-center">{service.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{service.desc}</p>
                              <Link to={service.url.url} className="mt-5 text-hover text-lg text-center">{service.url.title}</Link>
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
        </div>


        <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">

          <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-consultants-india-mumbai.png')}
                      alt="Hire Salesforce certified consultants india mumbai"
                      title="Niche Tech On-Demand Talent Services"
                      />
                </div>
              </div>

              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Power Your Growth Journey with On-Demand Salesforce Talent 														
              </h1><br />
              <p className="font-hairline text-justify">Whether you need to rapidly expand your operations or tackle complex projects, our on-demand model ensures that the right Salesforce resources are available when you need them. 
              </p><br/>
              <p className="font-hairline text-justify"><Link to="/services/staff-augmentation-services" class="underline text-blue-700">Tech Staff Augmentation Services</Link>
              </p><br/>
              </div>
              

          </div>
        </div>



        <div className="section-title text-center m-8">
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>Multi-Cloud Salesforce Solutions
          </span>
        </div>
        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Sales Cloud</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Elevate your sales strategies with Salesforce's powerful Sales Cloud, designed to optimize customer relationships and drive revenue growth.
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Service Cloud</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Enhance customer satisfaction and support efficiency with Salesforce's Service Cloud, empowering your team to provide exceptional service experiences.
                  
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Marketing Cloud</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">Transform your marketing campaigns into personalized journeys using Salesforce's Marketing Cloud, ensuring impactful interactions and measurable results.
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Commerce Cloud</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Elevate your e-commerce game with Salesforce's Commerce Cloud, offering seamless customer experiences across all channels, enhancing engagement and conversion rates.
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Data Cloud</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Personalize customer experience by capitalizing on your first-party data through Salesforce Data Cloud and improve customer relationships.
                  
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Experience Cloud</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">Create unique and effective experiences across multiple brand touchpoints to improve customer relation and build brand loyalty.
                  
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">Case Studies</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-consulting-services-usa-pharma.png')} alt="Salesforce consulting partners pharma case study | Cymterix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/b2b-commerce-cloud-pharma/" >Streamlining global wholesale operations with Salesforce & SAP for a pharmaceutical company</Link></h1>
                          
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partner-india-fintech.png')} alt="Salesforce consulting fintech company | Cymetrix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/fintech-community-cloud/">Revolutionizing bill discounting with a self-serving co-lending fintech platform</Link></h1>
                            
                        </div>
                    </div>
                </div>
            </div>




        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partners-license-types.png')} alt="Salesforce partners for licencing | Cymterix Software" title="Salesforce license types" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/02/understanding-salesforce-license-types-for-optimization-and-cost-reduction/" >Understanding Salesforce License Types For Optimization and Cost Reduction</Link></h1>
                            <p className="text-lg">Read about the core attributes of Salesforce.com licensing agreements.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-einstien-gpt-ai.png')} alt="Salesforce Einstien GPT | Cymetrix Software" title="Why Salesforce generative AI is the next big thing?" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/07/salesforce-einstein-gpt-why-generative-ai-is-the-next-big-thing/">Salesforce Einstein GPT: Why Generative AI is the next big thing?</Link></h1>
                            <p className="text-lg">Read about how Salesforce Einstien is revolutionizing the way CRM works.</p>
                        </div>
                    </div>
                </div>
            </div>

        <Testonimal />
        
        <div className="bg-gray-300 w-full flex flex-col items-center ">
            <div className="max-w-5xl mx-4 sm:mx-2 sma:mx-2">
                <h1 className="text-3xl w-full text-gray-800 text-uppercase text-center mt-20 mb-5 md:text-3xl sma:text-xl">
                A TRUSTED SALESFORCE PARTNER IN USA & INDIA
                </h1>
                <div className="flex flex-col md:flex-row items-center content-between my-10">
                  <img src={require('../../images/home/salesforce-consulting-partner.png')} className="w-48" alt="top Salesforce consulting partner company"/>
                  <p className="mx-5 sma:my-10 text-gray-700 text-justify">Cymetrix is one of the leading Salesforce implementation partners in USA with experience and expertise to drive Salesforce-powered digital transformation for your enterprise. Our offshore Salesforce development team can help clients leverage the Salesforce platform effectively to innovate and create value from customer operations.</p>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-center my-10 mb-20">
                  <img src={require('../../images/home/salesforce-full.png')} title="Leading Salesforce.org partner " alt="Salesforce partners - Cymetrix Software" className="w-48 h-30" />
                  <p className="mx-5 sma:my-10 text-gray-700 text-justify">Cymetrix is one of the leading Salesforce org partners and one of the top Salesforce consulting companies in USA. With expertise in delivering small-scale &large-scale complex CRM implementations, helping clients scale to market needs.
</p>
                </div>
            </div>
        </div>

        <div className="flex flex-col mx-auto items-center rounded-lg pb-10">
                    <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1"/>
                    <div className="px-6 py-4 bg-white  text-gray-700">
                        <h4 class="text-lg" style={{color: '#1e407b'}}><b>FAQs:</b></h4>
                        <br/>
                        <h4 class="text-lg"><b>Why choose Cymetrix software as your partner for Salesforce Consulting Services?</b></h4>
                        <p> As a Salesforce Consulting partner, we have access and deep expertise in the entire suite of Salesforce offerings and eco-system. They have a certified team of more than 150 consultants on Salesforce products. Our experience in designing and developing products and IPs on Salesforce platform enables us to design industry specific, customised solutions, with intuitive user interfaces.</p>
                        <br/>
                        <h4 class="text-lg"><b>As a Salesforce Partner, does Cymetrix offer on-demand Salesforce talent?</b></h4>
                        <p> Whether you need to rapidly expand your operations or tackle complex projects, our on-demand model ensures that the right Salesforce resources are available when you need them.</p>
                        <br/>
                        <h4 class="text-lg"><b>In which locations does Cymetrix provide Salesforce consulting and implementation services?</b></h4>
                        <p> We can provide near-shore consultation and offshore delivery powered by our network of offices in USA, UK, Europe, Japan and India.</p>

                    </div>
                </div>


      </Layout>
    );
}

export default SalesforceConsultingPage;
